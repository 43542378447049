
.styled-select {
    appearance: none;
    background-image: url('../../asset/down.png');
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 15px;
    padding-right: 24px;
    cursor: pointer; 
  }
  
  @keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
  }
  
  .spin {
    animation: spin 2s linear infinite; /* 2 seconds duration, linear timing, infinite repeat */
  }
  

  .link-container {
    opacity: 0;
    transition: transform 1s, opacity 1s;
  }
  
  .linkedin {
    transform: translateX(100%);
  }
  
  .twitter {
    transform: translateX(-100%);
  }
  
  .facebook {
    transform: translateX(0); /* No animation for Facebook, stays in place */
  }
  
  .link-container.show {
    opacity: 1;
    transform: translateX(0);
  }