/* The CSS

  
  .snow, .snow:before, .snow:after {
    height:50%;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    content: "";
  }
  
  .snow {
    animation-name: snow;
  }
  
  .snow:before {
    background-image: radial-gradient(3px 3px at 5px 5px, rgba(255,255,255,0.5), rgba(0,0,0,0));
    margin-left: -200px;
    opacity: 0.65;
    filter: blur(1.5px);
  }
  
  .snow:after {
    background-image: radial-gradient(5px 5px at 5px 5px, rgba(255,255,255,0.5), rgba(0,0,0,0));
    margin-left: -200px;
    opacity: 0.4;
    filter: blur(3px);
  }
  
  @keyframes snow {
    to {
      transform: translateY(100%);
    }
  }
   */




/*    
   body {
    margin: 0;
    overflow: hidden;
    background: #000;
  }
  
  i {
    position: absolute;
    width: auto;
    height: auto;
    border-radius: 50%;
    animation: screen infinite;
  }
   */
   .aaa {
    animation: fadeInRight 2s ease-in-out forwards; 
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0); 
    }
  }